import encryptData from "./cryptography";
async function PostHelper(url, data = null , token = null){
    
    const headers = {
        'Content-Type': 'application/json'
      };
      
    if(token){
        headers['hashKey'] = token;
    }  
    
    const requestOptions = {
      method: "POST",
      headers
    };

    if(data){
      const dataToSend = encryptData(data);
      const requestBody = {
        'request_data': dataToSend ,
        
      };
      requestOptions.body = JSON.stringify(requestBody);
    }
    
    // console.log('headers_json', headers);
    const response = await fetch(url, requestOptions);

      return response;
}
export default PostHelper;
