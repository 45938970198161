import React, { useEffect, useState } from "react";
import Menus from "../Menus";
import Footers from "../Footers";
import { format } from "date-fns";
import MenuTop from "../MenuTop";
import DataTable from 'react-data-table-component';
import { API_ROUTES } from "../../../utils/constants";
import Nulls from "../Nulls";
import Loader from "./Loaders/Loader";
import Swal from "sweetalert2";
import PostHelper from "../../../utils/PostHelper";
import { formatDate } from "../../../utils/TimeFormatter";
// import { formatInTimeZone } from "date-fns-tz";
// format(new Date(data.created_at), 'MMM d, yyyy h:mma')

const Guarantors = (props) => {
    const { capitalizeWords, objectToFormData, isAuthExpired, token, newNotify, getNotifyCounts, isOffcanvasActive, handleMenuToggle, notifications, fetchNotifys, isAdmins } = props;
    const [isFade, setIsFade] = useState(true)

    useEffect(() => {document.title = "Guarantors | Daniezo"}, []);

    const [isLoading, setIsLoading] = useState(true);
    const [datas, setDatas] = useState([]);
    const [status, setStatus] = useState('')
    const [dataTotal, setDataTotal] = useState(0);
    const [page, setPage] = useState(1);
    const countPerPage = 30;
    const [searchText, setSearchText] = useState('');
    const [selectedOptions, setSelectedOptions] = useState('');
    const [selectedValue, setSelectedValue] = useState('');


    const handleSelectChange = async (row, event) => {
        const newSelectedOptions = [...selectedOptions];
        const dropdown = event.target.value;
        newSelectedOptions[row.id] = dropdown;

        setSelectedOptions(newSelectedOptions);

        let captions = "";
        if(dropdown === "pending"){
            captions = "Pending";
        }else if(dropdown === "approved"){
            captions = "Approved";
        }else if(dropdown === "declined"){
            captions = "Declined";
        }
        
        if(captions !== ""){
            const result = await Swal.fire({
                title: dropdown !== "declined" ? `Change the status to ${captions}` : `Please enter a reason and continue`,
                html: dropdown !== "declined" ? 'This process can always be undone, continue?' : 
                `<div class="text-left swal_inputs col-12">
                    <textarea id="reason" class="swal2-textarea" placeholder="Enter a reason for decline"></textarea>
                </div>`,
                icon: 'question',
                iconHtml: '?',
                showCancelButton: true,
                confirmButtonColor: '#337ab7',
                cancelButtonColor: '#777',
                confirmButtonText: `Yes, Proceed`,
                preConfirm: () => {
                    if(dropdown === "declined"){
                        const reason = Swal.getPopup().querySelector('#reason').value;
                        if (!reason) {
                        Swal.showValidationMessage(`Please enter a reason`);
                        return;
                        }
                        return { reason: reason }
                    }
                  }
            });

            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Processing...',
                    text: "Please wait a second for a response...",
                    icon: 'success',
                    showConfirmButton: false,
                    confirmButtonColor: '#027937',
                    cancelButtonColor: '#d33',
                });
                try {
                    const dataString = { 
                        reason: dropdown === "declined" ? result.value.reason : '',
                        id: row.ids,
                        status: dropdown,
                        columns
                    };
                    // const response = await fetch(API_ROUTES.APPROVE_GUARANTOR, {
                    //     method: 'POST',
                    //     body: objectToFormData(dataString),
                    //     headers: {'hashKey': token}
                    // });
                    const response = await PostHelper(API_ROUTES.APPROVE_GUARANTOR, dataString,token)
                    const responseJSON = await response.json();
                    isAuthExpired(responseJSON); // check for expired login
    
                    if (response.ok) {
                        Swal.fire({
                            title: "Successful",
                            html: responseJSON.message,
                            icon: "success",
                            confirmButtonColor: '#393472',
                        }); 
                        setSelectedValue(row.id)
                        getDatas();
                        return;
                    } else {
                        Swal.fire({
                            title: 'Error',
                            html: responseJSON.message || 'An error occurred during the POST request.',
                            icon: 'error',
                        });
                        setSelectedValue(row.id);
                    }
                } catch (error) {
                    Swal.fire({
                        title: 'Error',
                        html: "Network Error! Kindly check your network connection",
                        icon: 'error',
                    });
                    setSelectedValue(row.id);
                }
                return;
            }
            setSelectedValue(row.id);
        }
    };


    const columns = [
        {
            name: 'Customer',
            selector: row => 
            row.userDetails 
            ?
            row.userDetails?.firstname !== null
            ?
            <div>{`${row.userDetails?.firstname} ${row.userDetails?.lastname}`}<div style={{ fontSize:'15px', marginTop:'4px', color:'#069' }}>({row.userDetails?.phone})</div></div>
            :
            row.userDetails?.email
            :
            <span style={{ color:'red', opacity:0.5, fontSize:'12px' }}>Customer Deleted</span>,
            sortable: true,
        },
        {
            name: 'Guarantor Name',
            // selector: row => row.names ? capitalizeWords(row.names) : <Nulls />,

            selector: row => 
            row.firstname 
            ?
            row?.firstname !== null
            ?
            <div style={{ textTransform:'capitalize' }}>{`${row?.firstname} ${row?.lastname ? row?.lastname : ""}`}</div>
            :
            <Nulls />
            :
            <Nulls />,

            sortable: true,
        },
        {
            name: "Status",
            selector: row => (
                <>
                    {
                        (() => {
                            return (
                                <>
                                    {
                                        row.status !== null ?
                                        <>
                                            <div>
                                                {
                                                    (row.status === "approved" || row.status === "declined") ?
                                                    <b style={{ color: row.status === "approved" ? 'green' : 'red', textTransform:'capitalize' }}>{row.status}</b>
                                                    :
                                                        (isAdmins &&(isAdmins.includes('can update guarantor') || isAdmins === "00")) ? (
                                                        <select 
                                                        key={row.id} 
                                                        defaultValue={selectedOptions[row.id] ? selectedOptions[row.id] : row.status} 
                                                        onChange={(event) => handleSelectChange(row, event)}
                                                        className='dropdowns'
                                                        style={{ width:'110px' }}
                                                    >
                                                        <option value="">-Select-</option>
                                                        <option value="pending">Pending</option>
                                                        <option value="approved">Approved</option>
                                                        <option value="declined">Declined</option>
                                                    </select>
                                                    ):<span></span>
                                                    

                                                }
                                            </div>
                                        </>
                                        :
                                        <Nulls />
                                    }
                                </>
                            );
                        })()
                    }
                </>
            )
        },
        {
            name: 'Guarantor Phone',
            selector: row => row.phone ? row.phone : <Nulls />,
            sortable: true,
        },
        {
            name: 'Guarantor Email',
            selector: row => row.email ? row.email : <Nulls />,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => row.created_at ? formatDate(new Date(row.created_at)) : <Nulls />,
        },
    ];

    const ExpandableComponent = ({ data }) => {
        return (
            <>
                <div className='text-left th_data'>
                    <div>
                        <font>Guarantor Names: </font> 
                        <span style={{ textTransform:'capitalize' }}>{`${data?.firstname} ${data?.lastname ? data?.lastname : ""}`}</span>
                    </div>
                </div>

                <div className='text-left th_data'>
                    <div><font>Date Created:</font> {data.created_at ? formatDate(new Date(data.created_at)): <Nulls />}</div>
                </div>
            </>
            
        );
    };

    
    const getDatas = async (isLoad=true, status="", filters="") => {
        const dataString = { 
            page: page,
            perPage: countPerPage,
            filters: filters,
        };
        setIsFade(true);
        try {
            // const response = await fetch(API_ROUTES.GUARANTORS, {
            //     method: "POST",
            //     body: objectToFormData(dataString),
            //     headers: {'hashKey': token}
            // });
            const response = await PostHelper(API_ROUTES.GUARANTORS, dataString,token)
            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login
            
            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                const totalData = responseJSON.data_total;
                setDataTotal(totalData);
                setDatas(responseData);
                // setStatus(responseData.status)
                // console.log(responseData)
                setIsFade(false);
                setIsLoading(false);
                return;
            }
            setIsFade(false);
            setIsLoading(false);
            setDatas([]);
        } catch (error) {
          
            setDatas([]);
            setIsLoading(false);
            setIsFade(false);
            Swal.fire({
                title: 'Error',
                html: "Network Error! Kindly check your network connection",
                icon: 'error',
            });
        }
    }
    
    useEffect(() => {
        getDatas();
        getNotifyCounts();
        fetchNotifys();
    }, [page, countPerPage, searchText]);


    const handleChange = async (event) => {
        const filters = event.target.value;
        getDatas(true, "", filters);
    }

    


    return (
        <>
            <div className="container-scroller">
                <MenuTop isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />
                
                <div className="container-fluid page-body-wrapper">
                    <Menus isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />

                    <div className="main-panel">
                        <div className="content-wrapper p-xs-0">
                            <div className="row grid-margin mt--10 mt-xs-0">
                                <div className="col-sm-12 mb-xs-40">
                                    <div className="col-12">
                                        <h2 className="mt-20 mt-xs-30 mb--20 mb-xs-0">Guarantors</h2>
                                    </div>

                                    <div className="offset-lg-8 col-lg-4 offset-sm-7 col-sm-5 mb-20 mt-xs-20 mb-xs-20">
                                        <div className="row">
                                            <div className="col-12">
                                                <input type="text" placeholder="Search by name..."
                                                    className="filter_text"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        isLoading ? <Loader /> 
                                    :

                                        <DataTable
                                            className={`mt-20 table_css ${isFade && 'opaqs'}`}
                                            columns={columns}
                                            data={datas}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={dataTotal}
                                            paginationPerPage={countPerPage}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true,
                                            }}
                                            onChangePage={(newPage) => setPage(newPage)}
                                            expandableRows
                                            expandableRowsComponent={ExpandableComponent}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Guarantors;