import React, { useEffect, useRef, useState } from "react";
import Menus from "../Menus";
import Footers from "../Footers";
import { format } from "date-fns";
import MenuTop from "../MenuTop";
import DataTable from 'react-data-table-component';
import { API_ROUTES } from "../../../utils/constants";
import Nulls from "../Nulls";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import ReactDOMServer from 'react-dom/server';
import Loader from "./Loaders/Loader";
import PostHelper from "../../../utils/PostHelper";
import { useLocation } from "react-router-dom";
import { formatDate } from "../../../utils/TimeFormatter";

import Unauthorized from "../unauthorized";


const LoanRequests = (props) => {
    const { capitalizeWords, objectToFormData, isAuthExpired, token, downloadLargeData, newNotify, getNotifyCounts, isOffcanvasActive, handleMenuToggle, notifications, fetchNotifys, isAdmins } = props;
    const [isFade, setIsFade] = useState(true);
    
    const {register, handleSubmit, formState: {errors, isValid, isSubmitting}} = useForm({
        mode: "onChange"
    });

    const adminLogins = JSON.parse(localStorage.getItem('userDataDani'));
    // console.log(adminLogins.responseDetails.id);
    const [adminId, setAdminId] = useState(adminLogins.responseDetails.id);

    const [totalUsers, setTotalUsers] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [pageReloader, setPageReloader] = useState('')
    const [totalReqs, setTotalReqs] = useState(0);
    const [totalRepay, setTotalRepay] = useState(0);
    const [totalIntr, setTotalIntr] = useState(0);
    const [totalPenalty, setTotalPenalty] = useState(0);
    const filterText = useRef(null);
    const [disabledButtons, setDisabledButtons] = useState({});
    const location = useLocation();

    // console.log('location name',location.pathname)

    useEffect(() => {document.title = "Loan Requests | Daniezo"}, []);


    const [datas, setDatas] = useState([]);
    const [dataTotal, setDataTotal] = useState(0);
    const [page, setPage] = useState(1);
    const countPerPage = 30;
    const [searchText, setSearchText] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedData, setSelectedData] = useState([]);
    const [isDivVisible, setDivVisibility] = useState(false);
    const [showSecDownload, setShowSecDownload] = useState(false);



    const months = Array.from({ length: 12 }, (_, index) => index + 1);


    const handleGetMoney = async (id, index) => {
        // console.log(id);
        const result = await Swal.fire({
            title: `Proceed to create installments?`,
            text: 'This process cannot be undone, continue?',
            icon: 'question',
            iconHtml: '?',
            showCancelButton: true,
            confirmButtonColor: '#337ab7',
            cancelButtonColor: '#777',
            confirmButtonText: `Yes, Proceed`,
        });
        if (result.isConfirmed) {
            // setButtonDisable(true);

            setDisabledButtons((prevDisabledButtons) => ({
                ...prevDisabledButtons,
                [id]: true,
            }));

            Swal.fire({
                title: 'Processing...',
                text: "Please wait a second for a response...",
                icon: 'success',
                showConfirmButton: false,
                confirmButtonColor: '#027937',
                cancelButtonColor: '#d33',
            });
            try {
                const dataString = { 
                    loan_id: id,
                };
                // const response = await fetch(API_ROUTES.CREATE_INSTALMENTS, {
                //     method: 'POST',
                //     body: objectToFormData(dataString),
                //     headers: {'hashKey': token}
                // });
                const response = await PostHelper(API_ROUTES.CREATE_INSTALMENTS,dataString,token)
                const responseJSON = await response.json();
                if (responseJSON.status.toString() === "200") {
                    Swal.fire({
                        title: "Successful",
                        html: responseJSON.message,
                        icon: "success",
                        confirmButtonColor: '#393472',
                    }); 
                    getDatas();
                    return;
                } else {
                    Swal.fire({
                        title: 'Error',
                        html: responseJSON.message || 'An error occurred during the POST request.',
                        icon: 'error',
                    });
                }
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    html: "Network Error! Kindly check your network connection",
                    icon: 'error',
                });
            }
        }
    }

    const handleExtend = async (id, e) => {
        const dropdowns = ReactDOMServer.renderToString(
            <div className="text-left swal_dropdown col-12">
              <select id="duration">
                <option value="1 days">1 day</option>
                <option value="2 days">2 days</option>
                <option value="5 days">5 days</option>
                <option value="1 weeks">1 week</option>
                <option value="2 weeks">2 weeks</option>
                <option value="3 weeks">3 weeks</option>
                {months.map((number) => (
                  <option key={number} value={`${number} months`}>
                    {number} months
                  </option>
                ))}
              </select>
            </div>
        );
        
        const result = await Swal.fire({
            title: 'Select duration to extend loan',
            html: dropdowns,
            confirmButtonText: 'Continue',
            icon: 'question',
            iconHtml: '?',
            focusConfirm: false,
            showCancelButton: true,
            cancelButtonColor: '#777',
            preConfirm: () => {
              const duration = Swal.getPopup().querySelector('#duration').value
              if (!duration) {
                Swal.showValidationMessage(`Please select a duration`);
                return;
              }
              return { duration }
            }
        })
        if (result.isConfirmed) {
            Swal.fire({
                title: 'Processing...',
                text: "Please wait a second for a response...",
                icon: 'success',
                showConfirmButton: false,
                confirmButtonColor: '#027937',
                cancelButtonColor: '#d33',
            });
            try {
                const dataString = { 
                    duration: result.value.duration,
                    id,
                };

                // const response = await fetch(API_ROUTES.EXTEND_DURATION, {
                //     method: 'POST',
                //     body: objectToFormData(dataString),
                //     headers: {'hashKey': token}
                // });
                
                const response = await PostHelper(API_ROUTES.EXTEND_DURATION, dataString, token)
                const responseJSON = await response.json();
                if (responseJSON.status.toString() === "200") {
                    Swal.fire({
                        title: "Successful",
                        html: responseJSON.message,
                        icon: "success",
                        confirmButtonColor: '#393472',
                    }); 
                    getDatas();
                    return;
                } else {
                    Swal.fire({
                        title: 'Error',
                        html: responseJSON.message || 'An error occurred during the POST request.',
                        icon: 'error',
                    });
                }
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    html: "Network Error! Kindly check your network connection",
                    icon: 'error',
                });
            }
        }
    }


    // console.log(datas);


    const columns = [
        {
            name: 'Customer',
            selector: row => 
                row?.firstname !== null 
                ?
                    <div>
                    {`${row?.firstname} ${row?.lastname}`}
                        <div style={{ fontSize:'15px', marginTop:'4px', color:'#069' }}>
                            ({row?.phone})
                            {
                                row.credit_score &&
                                <div style={{ color:'#777', fontSize:'12px', marginTop:1, }}>(Credit Score: {row.credit_score})</div>
                            }
                            {
                                row.user_declined === 1 &&
                                <div style={{ color:'red', fontSize:'12px' }}>User Declined Loan</div>
                            }
                        </div>
                    </div>
                :
                    row?.email,
            
            sortable: true,
        },
        {
          name: 'Requested',
          selector: row => row.amount_requested ? '₦'+parseFloat(row.amount_requested).toLocaleString() : '0.00',
        },

        {
            name: '1st Level',
            selector: row => {
                let colors = "";
                let statusLink = "";
    
                const dueDate = new Date(row.due_date);
                const currentDate = new Date();
                const isPastDue = currentDate > dueDate;

                if (row.loan_status === 'rejected') {
                    colors = "red";
                }
                if (row.loan_status === 'pending') {
                    colors = "orange";
                    if (row.loan_status === 'rejected') colors = "red";
                }
                if (row.loan_status === 'approved') {
                    colors = "green";
                }

                statusLink = (
                    <div>
                        {
                            row.user_declined === 0 ?
                            row.loan_status !== "approved" &&
                            <select onChange={(e) => handleLoanStatus(row, e, 1)} className='dropdowns' value={selectedValue !== "" ? selectedValue : row.loan_status} defaultValue={row.loan_status}>
                                <option value="approved">Approve</option>
                                <option value="rejected">Rejected</option>
                                <option value="pending">Pending</option>
                            </select>
                            :
                            <select className='dropdowns' value={selectedValue !== "" ? selectedValue : row.loan_status} defaultValue={row.loan_status} style={{ opacity:0.4 }} disabled>
                                <option value="approved">Approve</option>
                                <option value="rejected">Rejected</option>
                                <option value="pending">Pending</option>
                            </select>
                        }
                    </div>
                );
                return (
                    
                        (isAdmins && (isAdmins.includes("can approve 1st-level (Loan requests)") || isAdmins === "00" || isAdmins.includes("can write loan requests")))?(
                            <>
                            {   
                                row.loan_status !== 'rejected' ? 
                                <>
                                    {
                                        row.user_declined === 0 ?
                                        <b style={{ color:colors }}>{capitalizeWords(row.loan_status)}</b>
                                        :
                                        ""
                                    }
                                    {statusLink}
                                </>
                                :
                                <b style={{ color: colors }}>{capitalizeWords(row.loan_status)}</b>
                            }
                        </>
                        ):(<b style={{ color: colors }}>{capitalizeWords(row.loan_status)}</b>)
                    
                   
                );
            },
            sortable: true,
        },

         {
            name: '2nd Level',
            selector: row => {
                let colors = "";
                let statusLink = "";
    
                const dueDate = new Date(row.due_date);
                const currentDate = new Date();
                let isPastDue = false;
                if(row.due_date !== null){
                    isPastDue = currentDate > dueDate;
                }

                if (row.status1 === 'rejected') {
                    colors = "red";
                    if (row.loan_status === 'rejected') colors = "red";
                }
                if (row.status1 === 'pending') {
                    colors = "orange";
                    if (row.loan_status === 'rejected') colors = "red";
                    
                }
                if (row.status1 === 'approved') {
                    colors = "green";
                }
                statusLink = (
                    <div>
                        {
                            row.user_declined === 0 ?
                            row.status1 !== "approved" &&
                            <select onChange={(e) => handleLoanStatus(row, e, 2)} className='dropdowns' value={selectedValue !== "" ? selectedValue : row.status1} defaultValue={row.status1}>
                                <option value="approved">Approve</option>
                                <option value="rejected">Rejected</option>
                                <option value="pending">Pending</option>
                            </select>
                            :
                            
                            <select className='dropdowns' value={selectedValue !== "" ? selectedValue : row.status1} defaultValue={row.status1} style={{ opacity:0.4 }} disabled>
                                <option value="approved">Approve</option>
                                <option value="rejected">Rejected</option>
                                <option value="pending">Pending</option>
                            </select>
                        }
                    </div>
                );

                return (
                    isAdmins && (isAdmins.includes("can approve 2nd-level (Loan requests)") || isAdmins === "00" || isAdmins.includes("can write loan requests")) ?(
                        <>
                        {
                            row.loan_status !== 'rejected' ? 
                            <>
                                {
                                    row.user_declined === 0 &&
                                    <b style={{ color:colors }}>{row.status1 !== null && capitalizeWords(row.status1)}</b>
                                }
                                {statusLink}
                                {
                                (row.approve_status === "paid") ? (
                                    <div style={{ color: 'green', fontSize: '14px', fontWeight: '600' }}>(Paid)</div>
                                        
                                ) : (
                                    (isPastDue && row.status1 === "approved") && <div style={{ color: 'red', fontSize: '14px', fontWeight: '600' }}>
                                    (Loan Due)
                                </div>

                                )
                                
                                
                                }
                            </>
                            :
                            <b style={{ color: colors }}>{row.loan_status !== null && capitalizeWords(row.loan_status)}</b>
                        }
                    </>
                    ):(<b style={{ color: colors }}>{row.loan_status !== null && capitalizeWords(row.loan_status)}</b>)
                    
                );
            },
            sortable: true,
},

        {
            name: 'Duration',
            selector: row => row.duration ? row.duration : <Nulls />,
            sortable: true,
        },
        {
          name: 'Repayment',
          selector: row => row.repayment ? (
            <div style={{ color:parseFloat(row.repayment) >= parseFloat(row.amount_requested) && 'green', fontWeight: parseFloat(row.repayment) >= parseFloat(row.amount_requested) && '600' }}>₦{parseFloat(row.repayment).toLocaleString()}</div>
          ) : '0.00',
        },
        
        ...(isAdmins && (isAdmins.includes("can extend user loan") || isAdmins === "00" || isAdmins.includes("can write loan requests")) ? [{
            name: 'Action',
            selector: row => {
                return (
                    <>
                        {
                        parseFloat(row.repayment) < parseFloat(row.amount_requested) && row.status1 === "approved" ? 
                        <button className="custom_btn btn-primary" onClick={(e) => handleExtend(row.ids, e)}>Extend Loan</button> :
                        <button className="custom_btn btn-primary" style={{ opacity:'0.4' }}>Extend Loan</button>
                        }
                    </>
                )
            },
        }] : []),

        ...(isAdmins && (isAdmins.includes("can create Lidya instalment (Get our money)") || isAdmins === "00" || isAdmins.includes("can write loan requests") || isAdmins.includes("can view Get my money"))? [{
            name: '...',
            selector: row => {
                const canGetMoney = parseFloat(row.repayment) < parseFloat(row.amount_requested) && 
                row.status1 === "approved" &&
                row.collect_created === 1 && 
                row.client_direct_debit_id !== null && 
                (row.mandate_status !== 'Validating_BVN' || row.mandate_status !== 'Waiting_Mandate_Activation' || row.mandate_status !== 'awaiting_activation');

                return (
                <>
                    {
                    canGetMoney ? 
                    <button 
                        disabled={!!disabledButtons[row.id]} 
                        className="custom_btn" 
                        style={{ backgroundColor:'#f14141', color:'#fff' }} 
                        onClick={() => handleGetMoney(row.ids, row.id)}
                    >
                        Get Our Money
                    </button>
                    :
                    <button 
                        className="custom_btn btn-primary" 
                        style={{ opacity:'0.3', backgroundColor:'#f14141', color:'#fff' }}
                    >
                        Get Our Money
                    </button>
                    }
                </>
                );
            },
        }] : []),
        // {
        //     name: 'Date Requested',
        //     selector: row => row.created_at ? format(new Date(row.created_at), 'MMM d, yyyy h:mma') : <Nulls />,
        //     sortable: true,
        // },
        // {
        //     name: 'Date Approved',
        //     selector: row => row.date_approved ? format(new Date(row.date_approved), 'MMM d, yyyy h:mma') : <Nulls />,
        // },
    ];

    const ExpandableComponent = ({ data, index, handleToggle }) => {
        const totalToBePaid = parseFloat(data.amount_requested) + parseFloat(data.penalty);
        const dueDate = new Date(data.due_date);
        const currentDate = new Date();
        const isPastDue = currentDate > dueDate ? true : false;

        const adminName = data.admin?.firstname ? `${data.admin?.firstname} ${data.admin?.lastname}` : data.admin?.email;
        const adminName2 = data.admin2?.firstname ? `${data.admin2?.firstname} ${data.admin2?.lastname}` : data.admin2?.email;
        
        return (
          <div className='text-left th_data'>
            <div>
                {
                    data.user_declined === 0 &&
                    <font>Toggle Withdrawal: &nbsp;
                        {(isAdmins && (isAdmins.includes("can write loan requests") || isAdmins === "00"))?
                        (<span style={{ color:'#069' }}>
                            <button
                                className={`toggle-button ${data.toggled ? 'toggled' : ''}`}
                                onClick={() => handleToggle(index, data.ids)}
                            >
                                {data.toggled ? 'On' : 'Off'}
                            </button>
                        </span>) : <Unauthorized/>}
                        
                    </font>
                }
            </div>
            <div><font>Mandate Status: <span style={{ color:'#069' }}>{ data.mandate_status || <Nulls />}</span></font></div>
            <div><font>Admin Approved: <span style={{ color:'#069' }}>{adminName && adminName.length > 3 ? adminName : <Nulls />}</span></font></div>
            <div><font>Admin 2 Approved: <span style={{ color:'#069' }}>{adminName2 && adminName2.length > 3 ? adminName2 : <Nulls />}</span></font></div>
            <div><font>Date Approved:</font> {data.date_approved ? formatDate(new Date(data.date_approved)): <Nulls />}</div>
            <div><font>User Declined:</font> {data.user_declined <= 0 ? "No" : <font style={{ color:'red' }}>YES</font>}</div>
            {data.reject_reason && <div><font>Reject Reason:</font> {data.reject_reason}</div>}
            <div><font>Loan Reason:</font> {data.loan_reason ? data.loan_reason : <Nulls />}</div>
            <div><font>Loan Category:</font> {data.loan_category ? data.loan_category : <Nulls />}</div>
            <div><font>Amount Requested:</font> {data.amount_requested ? '₦'+parseFloat(data.amount_requested).toLocaleString() : '0.00'}</div>
            <div><font>Amount To Be Disbursed:</font> {data.amount_due ? '₦'+parseFloat(data.amount_due).toLocaleString() : '0.00'}</div>
            <div><font>Company Interest:</font> {data.interests ? '₦'+parseFloat(data.interests).toLocaleString() : '0.00'}</div>
            <div><font>Penalty:</font> {data.penalty ? <span style={{ color:'red' }}>₦{parseFloat(data.penalty).toLocaleString()}</span> : '0.00'}</div>
            <div><font>Repayment:</font> {data.repayment ? '₦'+parseFloat(data.repayment).toLocaleString() : '0.00'}</div>
            <div><font>Rolled Over:</font> {data.rollover ? 'Yes' : 'No'}</div>
            <div><font>Collect Created:</font> {data.collect_created ? 'Yes' : 'No'}</div>
            <div><font>Customer Direct Debit ID:</font> {data.client_direct_debit_id}</div>
            <div><font>Mandate Status:</font> {data.mandate_status}</div>
            

            {
                data.loan_instalments.length !== 0 &&
                <div className="col-md-10 col-12 pl-0 pb-10 mt-15">
                    <div style={{ backgroundColor:'#fff', borderRadius:'10px' }} className="pt-10 pb-5 pl-20 pr-20">
                        <font>INSTALMENTS:</font> 
                        <div className="row mt-10" style={{ backgroundColor:'#ccc', paddingTop:'5px' }}>
                            <div className="col-2">
                                <b>Amount</b>
                            </div>
                            <div className="col-2">
                                <b>Instalments</b>
                            </div>
                            <div className="col-2">
                                <b>Penalty</b>
                            </div>
                            <div className="col-2">
                                <b>Status</b>
                            </div>
                            <div className="col-2">
                                <b>Days</b>
                            </div>
                            <div className="col-2">
                                <b>Due Date</b>
                            </div>
                        </div>
                        {data.loan_instalments.map((loan_instalment) => (
                            <div className="row" key={loan_instalment.id}>
                                <div className="col-2">
                                    &#8358;{parseFloat(loan_instalment.amount).toLocaleString()}
                                </div>
                                <div className="col-2">
                                    {loan_instalment.instalments}
                                </div>
                                <div className="col-2">
                                    &#8358;{parseFloat(loan_instalment.penalty).toLocaleString()}
                                </div>
                                <div className="col-2">
                                    {loan_instalment.loan_status === "overdue" ? <span style={{ color:'red' }}>{loan_instalment.loan_status}</span> : loan_instalment.loan_status}
                                </div>
                                <div className="col-2">
                                    Day {loan_instalment.instalment_day}
                                </div>
                                <div className="col-2">
                                    {loan_instalment.due_date}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            }

            <div style={{ color:'green' }}><font>Total Amount User will Pay: {'₦'+parseFloat(totalToBePaid).toLocaleString()}</font></div>
            <div style={{ color:isPastDue && 'red' }}><font>Due Date:</font> {data.due_date ? format(new Date(data.due_date),'yyyy-MM-dd hh:mm:ss a'): <Nulls />}</div>
            <div><font>Loan Extended:</font> {data.extendeds > 0 ? `${data.extendeds} times` : <Nulls />}</div>
            <div><font>Date Requested:</font> {data.created_at ? formatDate(new Date(data.created_at)) : <Nulls />}</div>
            <div><font>Date Approved:</font> {data.date_approved ? format(new Date(data.date_approved),'yyyy-MM-dd hh:mm:ss a') : <Nulls />}</div>
            <div><font>Date Created:</font> {data.created_at ? formatDate(new Date(data.created_at))  : <Nulls />}</div>
          </div>
        );
    };

    
    const getDatas = async (isLoad=true, loan_status="pending", filters="", tables="loan_applicants") => {
        const dataString = { 
            page: page,
            perPage: countPerPage,
            loan_status,
            filters,
            tables,
        };
        
        setIsFade(true);
        try {
            // const response = await fetch(API_ROUTES.LOAN_REQUESTS, {
            //     method: "POST",
            //     body: objectToFormData(dataString),
            //     headers: {'hashKey': token}
            // });
            const response = await PostHelper(API_ROUTES.LOAN_REQUESTS, dataString, token)
            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login

            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data.requests;
                const totalData = responseJSON.data.data_total;
                setDataTotal(totalData);
                
                setDatas(responseData);
                // console.log('data',responseData);
                setIsFade(false);
                setTotalReqs(responseJSON.data.total_reqs);
                setTotalRepay(responseJSON.data.total_repay);
                setTotalIntr(responseJSON.data.total_interest);
                setTotalPenalty(responseJSON.data.total_penalty);
                setIsLoading(false);
                return;
            }
            setIsFade(false);
            setIsLoading(false);
            setDatas([]);
            setTotalReqs(0);
            setTotalRepay(0);
            setTotalIntr(0);
            setTotalPenalty(0);
        } catch (error) {
         
            Swal.fire({
                title: 'Error',
                html: "Network Error! Kindly check your network connection",
                icon: 'error',
            });
            setDatas([]);
            setIsLoading(false);
            setIsFade(false);
        }
    }
    
    useEffect(() => {
        getDatas();
        getNotifyCounts(); 
        fetchNotifys();
    }, [page, countPerPage, searchText,pageReloader]);


    const handleStatus = async (event) => {
        const filters = event.target.value;
        // console.log(filters)
        
        getDatas(true, filters);
        // console.log('datas',datas)
    }
    const handleChange = async (event) => {
        const filters = event.target.value;
        getDatas(true, "", filters);
    }


    const handleLoanStatus = async (row, event, levels) => {
        const dropdown = event.target.value;
        

        if(levels === 2 && row.admin_id === 0){ // if level 2 status is touched and admin 1 has not approved it
            Swal.fire({
                title: "Error!",
                html: "This is a 2-level approval, a different admin must act on the first level!",
                icon: "error",
                confirmButtonColor: '#393472',
            });
            return;
        }
        if(levels === 2 && row.admin_id > 0 && adminId === row.admin_id ){ // if its the same admin, that approved the 2, do not allow
            Swal.fire({
                title: "Error!",
                html: "The same admin cannot act on both approval",
                icon: "error",
                confirmButtonColor: '#393472',
            });
            return;
        }

        if(dropdown === "rejected"){
            const result = await Swal.fire({
                title: 'Please enter a reason and continue',
                html: `<div class="text-left swal_inputs col-12">
                        <textarea id="reason" class="swal2-textarea" placeholder="Enter a reason for decline"></textarea>
                    </div>`,
                confirmButtonText: 'Continue',
                icon: 'question',
                iconHtml: '?',
                focusConfirm: false,
                showCancelButton: true,
                cancelButtonColor: '#777',
                preConfirm: () => {
                  const reason = Swal.getPopup().querySelector('#reason').value
                  if (!reason) {
                    Swal.showValidationMessage(`Please enter a reason`);
                    return;
                  }
                  return { reason: reason }
                }
            })
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Processing...',
                    text: "Please wait a second for a response...",
                    icon: 'success',
                    showConfirmButton: false,
                    confirmButtonColor: '#027937',
                    cancelButtonColor: '#d33',
                });
                try {
                    const dataString = { 
                        reason: result.value.reason,
                        id: row.ids,
                        status: dropdown,
                        status_level: levels,
                    };
                    // console.log(dataString);
                    // return;
                    // const response = await fetch(API_ROUTES.APPROVE_LOAN, {
                    //     method: 'POST',
                    //     body: objectToFormData(dataString),
                    //     headers: {'hashKey': token}
                    // });
                   
                    const response = await PostHelper(API_ROUTES.APPROVE_LOAN, dataString, token)
                    const responseJSON = await response.json();
                    if (response.ok) {
                        Swal.fire({
                            title: "Successful",
                            html: "User Loan has been " + dropdown,
                            icon: "success",
                            confirmButtonColor: '#393472',
                        }); 
                        // setPageReloader(dataString.id)
                        getDatas();
                        return;
                    } else {
                        Swal.fire({
                            title: 'Error',
                            html: responseJSON.message || 'An error occurred during the POST request.',
                            icon: 'error',
                        });
                        // setSelectedValue(row.status);
                    }
                } catch (error) {
                    Swal.fire({
                        title: 'Error',
                        html: "Network Error! Kindly check your network connection",
                        icon: 'error',
                    });
                    setSelectedValue(row.loan_status);
                }
                return;
            }
            // setSelectedValue(row.status);
        }else if(dropdown === "approved"){
            const result = await Swal.fire({
                title: `Proceed to approve this loan?`,
                text: 'This process cannot be undone, continue?',
                icon: 'question',
                iconHtml: '?',
                showCancelButton: true,
                confirmButtonColor: '#337ab7',
                cancelButtonColor: '#777',
                confirmButtonText: `Yes, Proceed`,
            });
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Processing...',
                    text: "Please wait a second for a response...",
                    icon: 'success',
                    showConfirmButton: false,
                    confirmButtonColor: '#027937',
                    cancelButtonColor: '#d33',
                });
                try {
                    const dataString = { 
                        id: row.ids,
                        status: dropdown,
                        status_level: levels,
                    };
                    // const response = await fetch(API_ROUTES.APPROVE_LOAN, {
                    //     method: 'POST',
                    //     body: objectToFormData(dataString),
                    //     headers: {'hashKey': token}
                    // });
                    
                    const response = await PostHelper(API_ROUTES.APPROVE_LOAN, dataString, token)
                    const responseJSON = await response.json();
                    if (response.ok) {
                        Swal.fire({
                            title: "Successful",
                            //html: "User Loan has been " + dropdown,
                            html: responseJSON.message,
                            icon: "success",
                            confirmButtonColor: '#393472',
                        }); 
                        getDatas();
                        return;
                    } else {
                        Swal.fire({
                            title: 'Error',
                            html: responseJSON.message || 'An error occurred during the POST request.',
                            icon: 'error',
                        });
                        // setSelectedValue(row.status);
                    }
                } catch (error) {
                    Swal.fire({
                        title: 'Error',
                        html: "Network Error! Kindly check your network connection",
                        icon: 'error',
                    });
                    // setSelectedValue(row.status);
                }
                return;
            }
            // setSelectedValue(row.status);
        }
        // setSelectedValue(row.status);
    }


    const handleSelectedRows = (selectedRows) => {
        setSelectedData(selectedRows.selectedRows.map((row) => row.original));
    }


    const fetchDatas = async (apiData) => {
        
        const filterTexts = filterText.current.value;
        apiData.status = filterTexts;
        downloadLargeData(apiData, "loan_applicants", "-loan-requests-data",'/loan-request/');
        

    }

    const handleButtonClick = () => {
        setDivVisibility(!isDivVisible);
        setShowSecDownload(true);
    }
    const handleButtonClose = (e) => {
        e.preventDefault();
        setDivVisibility(!isDivVisible);
        setShowSecDownload(false);
    }

    const handleToggle = async (index, loan_id) => {
        const newToggledState = !datas[index].toggled;
        setDatas(prevDatas =>
            prevDatas.map((data, i) => {
                if (i === index) {
                    return { ...data, toggled: newToggledState };
                }
                return data;
            })
        );
        const customData = {
            loan_id,
            withdrawal_status: newToggledState,
        }
        // console.log(customData);
        // return;

        try {   
            // const response = await fetch(API_ROUTES.TOGGLE_WITHDRAWAL, {
            //     method: "POST",
            //     body: objectToFormData(customData),
            //     headers: {'hashKey': token}
            // });
            const response = await PostHelper(API_ROUTES.TOGGLE_WITHDRAWAL, customData, token)
            // const responseJSON = await response.json();
            
        } catch (error) {
            console.log(error);
        }
    }
    



    return (
        <>
            <div className="container-scroller">
                <MenuTop isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />
                
                <div className="container-fluid page-body-wrapper">
                    <Menus isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />

                    <div className="main-panel">
                        <div className="content-wrapper p-xs-0">
                            <div className="row grid-margin mt--10 mt-xs-0">
                                <div className="col-sm-12 mb-xs-40">

                                    <div className="col-12">
                                        <h2 className="mt-10 mb-10 mb-xs-0">Loan Requests</h2>
                                    </div>

                                   

                                    <div className="offset-xl-2_ col-xl-12 offset-sm-0 col-sm-12 mb-40 pl-0 pl-xs-10">
                                        <div className="row">
                                            <div className="col-sm-3 col-6 pr-10 pl-xs-15 pr-xs-10 mb-xs-15">
                                                <div className="card bg-primary-light card-shadow-primary">
                                                    <div className="card-body p-20">
                                                        <div className="stats1">
                                                            <h6 className="">Total Requested</h6>
                                                            <h3 className="mb-0 blue">&#8358;{parseFloat(totalReqs).toLocaleString()}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-3 col-6 pl-10 pr-10 pl-xs-5 pr-xs-15">
                                                <div className="card bg-primary-light card-shadow-primary">
                                                    <div className="card-body p-20">
                                                        <div className="stats1">
                                                            <h6 className="">Total Repayment</h6>
                                                            <h3 className="mb-0 blue">&#8358;{parseFloat(totalRepay).toLocaleString()}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-3 col-6 pl-10 pr-10 pl-xs-15 pr-xs-10">
                                                <div className="card bg-primary-light card-shadow-primary">
                                                    <div className="card-body p-20">
                                                        <div className="stats1">
                                                            <h6 className="">Total Interest</h6>
                                                            <h3 className="mb-0 blue">&#8358;{parseFloat(totalIntr).toLocaleString()}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-3 col-6 pl-10 pl-xs-5">
                                                <div className="card bg-primary-light card-shadow-primary">
                                                    <div className="card-body p-20">
                                                        <div className="stats1">
                                                            <h6 className="">Total Penalty</h6>
                                                            <h3 className="mb-0 blue">&#8358;{parseFloat(totalPenalty).toLocaleString()}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                     <div className="offset-lg-7 col-lg-5 offset-sm-5 col-sm-7 mb-20 mt-xs-20 mb-xs-20 mr-0">
                                        <div className="row">
                                            <div className="col-5 text-end pr-5">
                                                <select onChange={handleStatus} className='filter_text' ref={filterText}>
                                                    <option value="" >-All-</option>
                                                    <option value="active" >Active</option>
                                                    <option value="approved">Approved</option>
                                                    <option value="rejected">Rejected</option>
                                                    <option value="pending" selected>Pending</option>
                                                </select>
                                            </div>
                                            <div className="col-7 pl-5">
                                                <input type="text" placeholder="Search by name..."
                                                    className="filter_text"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div> 

                                    <div className="mt-5">
                                        <form onSubmit={handleSubmit(fetchDatas)}>

                                            <div className={`row sliding-div ${isDivVisible ? 'visible' : ''}`}>
                                                <div className="col-2 text-right pr-5">
                                                    <button className="close_btns" onClick={(e) => handleButtonClose(e)}>x</button>
                                                </div>
                                                <div className="col-5 pl-0 pr-0">
                                                    <input type="date" {...register("dateFrom")} />
                                                </div>
                                                <div className="col-5 pl-0">
                                                    <input type="date" {...register("dateTo")} />
                                                </div>
                                            </div>

                                            {
                                                !showSecDownload &&
                                                <button onClick={handleButtonClick} className="showDateBtn"  ><i className="fa fa-print"></i> <font className="d-none d-sm-inline-block">Download</font> </button>
                                            }
                                            {
                                                showSecDownload &&
                                                <button type="submit" 
                                                disabled={isSubmitting || !isValid} 
                                                style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}
                                                className="showDateBtn"
                                                ><i className="fa fa-print"></i> <font className="d-none d-sm-inline-block">{(isSubmitting && !isValid) ? "Downloading" : "Download"}</font> </button>
                                            }

                                        </form>
                                    </div>

                                    {
                                        isLoading ? <Loader /> 
                                        :

                                        <DataTable
                                            // title="Loan Requests"
                                            className={`mt-100 pt-5 table_css1 ${isFade && 'opaqs'}`}
                                            columns={columns}
                                            data={datas}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={dataTotal}
                                            paginationPerPage={countPerPage} 
                                            paginationComponentOptions={{
                                                noRowsPerPage: true,
                                            }}
                                            onChangePage={(newPage) => setPage(newPage)}
                                            expandableRows
                                            // expandableRowsComponent={ExpandableComponent}
                                            expandableRowsComponent={({ data }) => (
                                                <ExpandableComponent
                                                    data={data}
                                                    index={datas.findIndex(d => d === data)}
                                                    handleToggle={handleToggle}
                                                />
                                            )}
                                            onSelectedRowsChange={handleSelectedRows}
                                        />
                                    }

                                </div>
                            </div>
                        </div>
                        
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoanRequests;