export function formatDate(dateString) {
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };
    // const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    // return formattedDate;

    const date = new Date(dateString);

    // const hourAdjustment = parseInt(import.meta.env.VITE_TIME_ZONE_DIFF, 10) || 0;
    // date.setHours(date.getHours() + hourAdjustment);

    date.setHours(date.getHours() - 1);

    const formattedDate = date.toLocaleDateString('en-US', options);
    // const formattedDate = date.toUTCString('en-US', options);
    return formattedDate;
}