import React, { useEffect, useState } from "react";
import Menus from "../Menus";
import Footers from "../Footers";
import { format } from "date-fns";
import MenuTop from "../MenuTop";
import DataTable from 'react-data-table-component';
import { API_ROUTES } from "../../../utils/constants";
import Nulls from "../Nulls";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import Loader from "./Loaders/Loader";
import PostHelper from "../../../utils/PostHelper";
import { formatDate } from "../../../utils/TimeFormatter";




const PasswordRequests = (props) => {
    const { capitalizeWords, objectToFormData, isAuthExpired, token, downloadLargeData, newNotify, getNotifyCounts, isOffcanvasActive, handleMenuToggle, notifications, fetchNotifys, isAdmins } = props;
    const [isFade, setIsFade] = useState(true);

    useEffect(() => {document.title = "Password Requests | Daniezo"}, []);

    const {register, handleSubmit, formState: {errors, isValid, isSubmitting}} = useForm({
        mode: "onChange"
    });

    const [isLoading, setIsLoading] = useState(true);
    const [datas, setDatas] = useState([]);
    const [dataTotal, setDataTotal] = useState(0);
    const [page, setPage] = useState(1);
    const countPerPage = 30;
    const [searchText, setSearchText] = useState('');
    const [isDivVisible, setDivVisibility] = useState(false);
    const [showSecDownload, setShowSecDownload] = useState(false);
    const [selectedValue, setSelectedValue] = useState(0);


    const columns = [
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => {
                let colors = "";
                let statusLink = "";
                let status1 = "Pending";

                const dueDate = new Date(row.due_date);
                const currentDate = new Date();
                const isPastDue = currentDate > dueDate ? true : false;

                if(row.status === 0){
                    colors = "orange";
                    statusLink = 
                    <div>
                        <select onChange={(e) => handleStatus(row, e)} className='dropdowns' value={selectedValue !== "" ? selectedValue : row.status} defaultValue={row.status}>
                            <option value="0">Pending</option>
                            <option value="1">Answered</option>
                        </select>
                    </div>
                }
                if(row.status === 1){
                    colors = "green";
                    status1 = "Answered";
                }
                return (
                    <>
                        <b style={{ color:colors }}>{status1}</b>
                        {statusLink}
                    </>
                )
            },
            sortable: true,
        },
        // {
        //     name: 'Unique URL',
        //     selector: row => row.unique_url ? row.unique_url : <Nulls />,
        // },
        {
            name: 'Date Created',
            selector: row => row.created_at ? formatDate(new Date(row.created_at)) : <Nulls />,
            sortable: true,
        },
        {
            name: 'Date Updated',
            selector: row => row.updated_at ? formatDate(new Date(row.updated_at)) : <Nulls />,
        },
    ];

    const ExpandableComponent = ({ data }) => {
        return (
          <div className='text-left th_data'>

            <div style={{ textTransform:'none' }}><font>Email:</font> {data.email ? data.email : <Nulls />}</div>
            <div style={{ textTransform:'none' }}><font>Unique URL:</font> {data.unique_url ? data.unique_url : <Nulls />}</div>
          </div>
        );
    };


    const handleStatus = async (row, event) => {
        const dropdown = event.target.value;
        setSelectedValue(dropdown);

        const result = await Swal.fire({
            title: `Proceed to send this admin new login details via thier email?`,
            text: 'This process cannot be undone, continue?',
            icon: 'question',
            iconHtml: '?',
            showCancelButton: true,
            confirmButtonColor: '#337ab7',
            cancelButtonColor: '#777',
            confirmButtonText: `Yes, Proceed`,
        });
        if (result.isConfirmed) {
            Swal.fire({
                title: 'Processing...',
                text: "Please wait a second for a response...",
                icon: 'success',
                showConfirmButton: false,
                confirmButtonColor: '#027937',
                cancelButtonColor: '#d33',
            });
            try {
                const dataString = { 
                    email: row.email,
                    status: dropdown,
                };
                // const response = await fetch(API_ROUTES.SEND_NEW_PASSWORD, {
                //     method: 'POST',
                //     body: objectToFormData(dataString),
                //     headers: {'hashKey': token}
                // });
               
                const response = await PostHelper(API_ROUTES.SEND_NEW_PASSWORD, dataString, token)
                const responseJSON = await response.json();
                if (responseJSON.status.toString() === "200") {
                    Swal.fire({
                        title: "Successful",
                        html: responseJSON.message,
                        icon: "success",
                        confirmButtonColor: '#393472',
                    }); 
                    getDatas();
                    return;
                } else {
                    Swal.fire({
                        title: 'Error',
                        html: responseJSON.message || 'An error occurred during the POST request.',
                        icon: 'error',
                    });
                    setSelectedValue(row.status);
                }
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    html: "Network Error! Kindly check your network connection",
                    icon: 'error',
                });
                setSelectedValue(row.status);
            }
            return;
        }
        setSelectedValue(row.status);
        
    }

    
    const getDatas = async (isLoad=true, status="", filters="") => {
        const dataString = { 
            page: page,
            perPage: countPerPage,
            status: status,
            filters: filters,
        };
        setIsFade(true);
        try {
            // const response = await fetch(API_ROUTES.VIEW_PASSWORD_REQUESTS, {
            //     method: "POST",
            //     body: objectToFormData(dataString),
            //     headers: {'hashKey': token}
            // });
           
            const response = await PostHelper(API_ROUTES.VIEW_PASSWORD_REQUESTS,dataString,token)
            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login
            
            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                const totalData = responseJSON.data_total;
                setDataTotal(totalData);
                setDatas(responseData);
                setIsFade(false);
                setIsLoading(false);
                return;
            }
            setIsFade(false);
            setIsLoading(false);
            setDatas([]);
        } catch (error) {
            console.log('Error getting data: ', error);
            setDatas([]);
            setIsLoading(false);
            setIsFade(false);
            Swal.fire({
                title: 'Error',
                html: "Network Error! Kindly check your network connection",
                icon: 'error',
            });
        }
    }
    
    useEffect(() => {
        getDatas();
        getNotifyCounts();
        fetchNotifys();
    }, [page, countPerPage, searchText]);

    const handleChange = async (event) => {
        const filters = event.target.value;
        getDatas(true, "", filters);
    }

    const fetchDatas = async (apiData) => {
        downloadLargeData(apiData, "transactions", "-transactions-data");
    }

    const handleButtonClick = () => {
        setDivVisibility(!isDivVisible);
        setShowSecDownload(true);
    }
    const handleButtonClose = (e) => {
        e.preventDefault();
        setDivVisibility(!isDivVisible);
        setShowSecDownload(false);
    }



    return (
        <>
            <div className="container-scroller">
                <MenuTop isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />
                
                <div className="container-fluid page-body-wrapper">
                    <Menus isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />

                    <div className="main-panel">
                        <div className="content-wrapper p-xs-0">
                            <div className="row grid-margin mt--20 mt-xs-0">
                                <div className="col-lg-10 mb-xs-40">
                                    <div className="table-responsive_">

                                        <div className="col-12">
                                            <h3 className="mt-30 mt-xs-30 mb--20 mb-xs-0">Admin Password Requests</h3>
                                        </div>

                                        <div className="offset-lg-8 col-lg-4 offset-sm-7 col-sm-5 mb-20 mt-xs-20 mb-xs-20">
                                            <div className="row">
                                                <div className="col-12">
                                                    <input type="text" placeholder="Search by name..."
                                                        className="filter_text"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            isLoading ? <Loader /> 
                                        :

                                            <DataTable
                                                className={`mt-5 pt-5 table_css ${isFade && 'opaqs'}`}
                                                columns={columns}
                                                data={datas}
                                                highlightOnHover
                                                pagination
                                                paginationServer
                                                paginationTotalRows={dataTotal}
                                                paginationPerPage={countPerPage}
                                                paginationComponentOptions={{
                                                    noRowsPerPage: true,
                                                }}
                                                onChangePage={(newPage) => setPage(newPage)}
                                                expandableRows
                                                expandableRowsComponent={ExpandableComponent}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}

export default PasswordRequests;