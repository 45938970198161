import React, { useEffect, useState } from "react";
import Menus from "../Menus";
import Footers from "../Footers";
import { format } from "date-fns";
import MenuTop from "../MenuTop";
import DataTable from 'react-data-table-component';
import { API_ROUTES } from "../../../utils/constants";
import Nulls from "../Nulls";
import Loader from "./Loaders/Loader";
import Swal from "sweetalert2";
import PostHelper from "../../../utils/PostHelper";
import { formatDate } from "../../../utils/TimeFormatter";



const Aggregators = (props) => {
    const { capitalizeWords, objectToFormData, isAuthExpired, token, newNotify, getNotifyCounts, isOffcanvasActive, handleMenuToggle, notifications, fetchNotifys, isAdmins } = props;
    const [isFade, setIsFade] = useState(true)

    useEffect(() => {document.title = "Aggregators | Daniezo"}, []);

    const [isLoading, setIsLoading] = useState(true);
    const [datas, setDatas] = useState([]);
    const [dataTotal, setDataTotal] = useState(0);
    const [page, setPage] = useState(1);
    const countPerPage = 30;
    const [searchText, setSearchText] = useState('');
    const [titleName, setTitleName] = useState('');
    const [userRows, setUserRows] = useState('');
    const [userDetls, setUserDetls] = useState('Loading...');



    const openModal = (rows) => {
        const names = rows.firstname ? `${rows.firstname} ${rows.lastname}` : rows.email;
        setUserRows(rows);
        setTitleName(`${names} Details`);

        const user_details = 
        <>
            <div className="row mb-10">
                <div className="col-4">Name</div>
                <div className="col-8">{names}</div>
            </div>
            <div className="row mb-10">
                <div className="col-4">Phone</div>
                <div className="col-8">{rows.phone} (Verified: {rows.phone_verified})</div>
            </div>
            <div className="row mb-10">
                <div className="col-4">Email</div>
                <div className="col-8">{rows.email}</div>
            </div>
            <div className="row mb-10">
                <div className="col-4">BVN</div>
                <div className="col-8">{
                    rows.bvn
                    ?
                    rows.bvn > 0 ? rows.bvn : <Nulls />
                    :
                    <Nulls />} (Verified: {rows.bvn_verified})
                </div>
            </div>
            <div className="row mb-10">
                <div className="col-4">NIN</div>
                <div className="col-8">{rows.nin ? rows.nin : <Nulls />}</div>
            </div>
            <div className="row mb-10">
                <div className="col-4">DOB</div>
                <div className="col-8">{rows.dob ? rows.dob : <Nulls />}</div>
            </div>
            <div className="row mb-10">
                <div className="col-4">Virtual Account</div>
                <div className="col-8">{rows.virtual_acct ? rows.virtual_acct : <Nulls />}</div>
            </div>
        </>
        setUserDetls(user_details);
    }


    const columns = [
        {
            name: 'Aggregator',
            selector: row => row.aggregatorDetails?.firstname ? <span>{`${row.aggregatorDetails?.firstname} ${row.aggregatorDetails?.lastname}`}</span> : row.aggregatorDetails?.email,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: row => row.aggregatorDetails?.phone ? row.aggregatorDetails?.phone : <Nulls />,
        },
        {
            name: 'Date',
            selector: row => row.created_at ? formatDate(new Date(row.created_at)) : <Nulls />,
        },
    ];

    const ExpandableComponent = ({ data }) => {
        return (
          <div className='text-left th_data'>
            <div><font>DOWNLINES</font></div>
            {
                data.referreds !== 0 && data.referreds.map((referred) => {
                    const names = referred?.firstname 
                    ? 
                    <div>
                        {`${referred?.firstname} ${referred?.lastname}`}
                        <div style={{ fontSize:'14px', marginTop:'4px', color:'#069' }}>({referred?.phone})</div>
                    </div> 
                    :
                    <div>
                        <span style={{ textTransform:'none' }}>{referred?.email}</span>
                        <div style={{ fontSize:'14px', marginTop:'4px', color:'#069' }}>({referred?.phone})</div>
                    </div> 

                    let colors = "";
                    let statusLink = "Pending";
                    if(referred.status === 1){
                        colors = "green";
                        statusLink = "Accepted";
                    }
                    if(referred.status === 0){
                        colors = "orange";   
                    }
                    return (
                        <div className="row mb-0" key={referred.id}>
                            <div className="col-4">
                                {names} 
                                {
                                    ( isAdmins && (isAdmins.includes("can update aggregator") || isAdmins === "00"))&&(
                                        <span 
                                        data-toggle="modal" data-target="#view_details" 
                                        onClick={() => openModal(referred)}
                                        style={{ fontSize:'13px', color:'green', marginTop:'-5px', marginBottom:'5px', display:'block', cursor:'pointer' }}>(View user) 
                                        </span>
                                    )
                                }
                                
                                
                            </div>
                            <div className="col-4">
                                <b style={{ color:colors }}>{capitalizeWords(statusLink)}</b>
                            </div>
                            <div className="col-4">
                                {referred.created_at ? <span style={{ fontSize:'14px' }}>{formatDate(new Date(referred.created_at))}</span> : <Nulls />}
                            </div>
                        </div>
                    );
                })
            }
          </div>
        );
    };

    
    const getDatas = async (isLoad=true, status="", filters="") => {
        const dataString = { 
            page: page,
            perPage: countPerPage,
            filters: filters,
        };
        setIsFade(true);
        try {
            // const response = await fetch(API_ROUTES.AGGREGATORS, {
            //     method: "POST",
            //     body: objectToFormData(dataString),
            //     headers: {'hashKey': token}
            // });
            const response = await PostHelper(API_ROUTES.AGGREGATORS, dataString, token)
            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login
            
            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                const totalData = responseJSON.data_total;
                setDataTotal(totalData);
                setDatas(responseData);
                setIsFade(false);
                setIsLoading(false);
                return;
            }
            setIsFade(false);
            setIsLoading(false);
            setDatas([]);
        } catch (error) {
            
            Swal.fire({
                title: 'Error',
                html: "Network Error! Kindly check your network connection",
                icon: 'error',
            });
            setDatas([]);
            setIsLoading(false);
            setIsFade(false);
        }
    }
    
    useEffect(() => {
        getDatas();
        getNotifyCounts();
        fetchNotifys();
    }, [page, countPerPage, searchText]);


    const handleChange = async (event) => {
        const filters = event.target.value;
        getDatas(true, "", filters);
    }

    


    return (
        <>
            <div className="modal fade" id="view_details" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel-2" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header p-15">
                            <h4 className="modal-title" id="exampleModalLabel-2">{titleName}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body p-10">
                            {userDetls}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-scroller">
                <MenuTop isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />
                
                <div className="container-fluid page-body-wrapper">
                    <Menus isAdmins={isAdmins} notifications={notifications} newNotify={newNotify} isOffcanvasActive={isOffcanvasActive} handleMenuToggle={handleMenuToggle} />

                    <div className="main-panel">
                        <div className="content-wrapper p-xs-0">
                            <div className="row grid-margin mt--10 mt-xs-0">
                                <div className="col-sm-8 col-sm-10 mb-xs-40">
                                    <div className="col-12">
                                        <h2 className="mt-20 mt-xs-30 mb--20 mb-xs-0">Aggregators</h2>
                                    </div>

                                    <div className="offset-lg-8 col-lg-4 offset-sm-7 col-sm-5 mb-20 mt-xs-20 mb-xs-20">
                                        <div className="row">
                                            <div className="col-12">
                                                <input type="text" placeholder="Search by name..."
                                                    className="filter_text"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        isLoading ? <Loader /> 
                                    :

                                        <DataTable
                                            className={`mt-20 table_css ${isFade && 'opaqs'}`}
                                            columns={columns}
                                            data={datas}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={dataTotal}
                                            paginationPerPage={countPerPage}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true,
                                            }}
                                            onChangePage={(newPage) => setPage(newPage)}
                                            expandableRows
                                            expandableRowsComponent={ExpandableComponent}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Aggregators;