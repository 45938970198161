import React from 'react'

function Unauthorized() {
  return (
    <>
      <span style={{ color:'red', fontWeight:'400', fontSize:'15px' }}>Unauthorized</span>
    </>
  )
}

export default Unauthorized


